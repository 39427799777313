import { degrees, PDFDocument, rgb } from 'pdf-lib';
import { saveAs } from 'file-saver';
import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';

const PdfEditorParentFile = () => {
  const [file, setFile] = useState(null);

  const onFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const editPdf = async () => {
    if (file) {
      const arrayBuffer = await file.arrayBuffer();
      const pdfDoc = await PDFDocument.load(arrayBuffer);

      const pages = pdfDoc.getPages();
      const firstPage = pages[0];
      const { width, height } = firstPage.getSize();

      firstPage.drawText('Hello, this is the edited text!', {
        x: 50,
        y: height / 2 + 300,
        size: 50,
        color: rgb(0, 0.53, 0.71),
        rotate: degrees(0),
      });

      const pdfBytes = await pdfDoc.save();
      saveAs(new Blob([pdfBytes], { type: 'application/pdf' }), 'edited.pdf');
    }
  };

  return (
    <div>
      <input type="file" onChange={onFileChange} accept="application/pdf" />
      {file && (
        <div>
          <button onClick={editPdf}>Edit PDF</button>
          <Document file={URL.createObjectURL(file)}>
            <Page pageNumber={1} />
          </Document>
        </div>
      )}
    </div>
  );
};

export default PdfEditorParentFile;
