import './App.css';
import JasonAppend from './Component/JasonAppend';
import Particle from './Component/Particle/Particle';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PdfEditorParentFile from './Component/PdfEditor/PdfEditorParentFile';
import WordEditorData from './Component/WordEditor/CommonDesign/WordEditorData';
import ExcelParentFile from './Component/ExcelEditor/CommonDesign/ExcelParentFile';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<WordEditorData />} />
          <Route exact path="/particle" element={<Particle />} />
          <Route exact path="/jason" element={<JasonAppend />} />
          <Route exact path="/excel" element={<ExcelParentFile />} />
          <Route exact path="/pdf" element={<PdfEditorParentFile />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
