import React, { useEffect, useState } from 'react'
import Toolbar from './Toolbar';
import EditorWritingPage from './EditorWritingPage';
import html2pdf from 'html2pdf.js/dist/html2pdf.bundle';

const WordEditorData = () => {
    const [pages, setPages] = useState([0]);
    const [tables, setTables] = useState([]);
    const [columns, setColumns] = useState(1);
    const [tocData, setTocData] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [imageSrc, setImageSrc] = useState(null);
    const [personData, setPersonData] = useState([]);
    const [activeTab, setActiveTab] = useState('Home');
    const [showHeader, setShowHeader] = useState(false);
    const [showFooter, setShowFooter] = useState(false);
    const [showReport, setShowReport] = useState(false);
    const [selectedCell, setSelectedCell] = useState(null);
    const [imageToInsert, setImageToInsert] = useState(null);
    const [emojiToInsert, setEmojiToInsert] = useState(null);
    const [shapeToInsert, setshapeToInsert] = useState(null);
    const [tableToInsert, setTableToInsert] = useState(null);
    const [orientation, setOrientation] = useState('portrait');
    const [selectedTableIndex, setSelectedTableIndex] = useState(null);
    const [shapeSize, setShapeSize] = useState({ width: 100, height: 100 });
    const [pageSize, setPageSize] = useState({ width: '8.5in', height: '11in' });
    const [margin, setMargin] = useState({ top: '.25in', bottom: '.25in', left: '.5in', right: '.5in' });

    const handleLinePositionChange = (newLeftMargin) => {
        setMargin((prev) => ({
            ...prev,
            left: `${newLeftMargin}px`,
        }));
    };

    const handleReportButtonClick = () => {
        setShowReport(!showReport);
    };

    const handleMarginChange = (newMargin) => {
        setMargin(newMargin);
    };

    const handleEmojiInsert = (emoji) => {
        setEmojiToInsert(emoji);
    };

    const handleShapeInsert = (shape) => {
        setshapeToInsert(shape);
    };

    const handleSizeChange = (newSize) => {
        setPageSize(newSize);
    };

    const handleColumnChange = (columnLayout) => {
        setColumns(columnLayout);
    };

    const handleImageInsert = (imageSrc) => {
        setImageToInsert(imageSrc);
    };

    const toggleHeader = () => {
        setShowHeader(prev => !prev);
    };

    const toggleFooter = () => {
        setShowFooter(prev => !prev);
    };

    const handlePrint = () => {
        const printWindow = window.open('', '_blank');
        const pageContents = pages.map((_, index) => {
            const page = document.querySelectorAll('.text-editor')[index];
            return page ? page.innerHTML : '';
        }).join('<div class="page-break"></div>');

        printWindow.document.write(`
            <html>
                <head>
                    <style>
                        @media print {
                            body {
                                margin: 0;
                                padding: 0;
                            }
                            .text-editor {
                                page-break-after: always;
                                border: none;
                            }
                            .page-footer {
                                position: fixed;
                                bottom: 0;
                                width: 100%;
                                text-align: center;
                            }
                            .page-header {
                                position: fixed;
                                top: 0;
                                width: 100%;
                                text-align: center;
                            }
                            .ruler { display: none; } /* Hide ruler when printing */
                            table {
                                border-collapse: collapse;
                                width: 100%;
                            }
                            table, th, td {
                                border: 1px solid black;
                                padding: 5px;
                            }
                        }
                    </style>
                </head>
                <body>
                    ${pageContents}
                    <script>
                        window.print();
                    </script>
                </body>
            </html>
        `);

        printWindow.document.close();
    };

    const handleConvertToPDF = () => {
        const element = document.querySelector('.text-editor-container');
        html2pdf()
            .from(element)
            .save();
    };

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setImageSrc(reader.result);
            };
            reader.readAsDataURL(file);
        }
        setIsOpen(false);
    };

    const [tableProperties, setTableProperties] = useState({
        borderWidth: 1,
        columnWidth: 80,
        pinHeaderRows: 1,
        cellPadding: 5,
        minRowHeight: 40,
        borderColor: '#000000',
        cellAlignment: 'TopLeft',
        cellBackgroundColor: '#ffffff',
    });

    const getCellAlignmentStyles = (alignment) => {
        switch (alignment) {
            case 'TopLeft':
                return { verticalAlign: 'top', textAlign: 'left' };
            case 'TopCenter':
                return { verticalAlign: 'top', textAlign: 'center' };
            case 'TopRight':
                return { verticalAlign: 'top', textAlign: 'right' };
            case 'MiddleLeft':
                return { verticalAlign: 'middle', textAlign: 'left' };
            case 'MiddleCenter':
                return { verticalAlign: 'middle', textAlign: 'center' };
            case 'MiddleRight':
                return { verticalAlign: 'middle', textAlign: 'right' };
            case 'BottomLeft':
                return { verticalAlign: 'bottom', textAlign: 'left' };
            case 'BottomCenter':
                return { verticalAlign: 'bottom', textAlign: 'center' };
            case 'BottomRight':
                return { verticalAlign: 'bottom', textAlign: 'right' };
            default:
                return { verticalAlign: 'top', textAlign: 'left' };
        }
    };

    const handleInsertTable = (table) => {
        setTableToInsert(table);
    };

    useEffect(() => {
        if (tableToInsert) {
            const cells = Array(tableToInsert.rows)
                .fill(0)
                .map(() =>
                    Array(tableToInsert.columns).fill({
                        backgroundColor: tableProperties.cellBackgroundColor,
                    })
                );

            const newTable = { ...tableToInsert, cells };
            setTables([...tables, newTable]);
        }
    }, [tableToInsert]);

    const handleCellChange = (rowIndex, colIndex, property, value) => {
        const updatedTables = [...tables];
        updatedTables[selectedTableIndex].cells[rowIndex][colIndex] = {
            ...updatedTables[selectedTableIndex].cells[rowIndex][colIndex],
            [property]: value,
        };
        setTables(updatedTables);
    };

    const updateTableProperties = (updatedProperties) => {
        setTableProperties(updatedProperties);
    };

    const handleAddRow = (index) => {
        const updatedTables = [...tables];
        updatedTables[index].rows += 1;
        const newCellsRow = Array(updatedTables[index].columns).fill({
            backgroundColor: tableProperties.cellBackgroundColor,
        });
        updatedTables[index].cells.push(newCellsRow);
        setTables(updatedTables);
    };

    const handleAddColumn = (index) => {
        const updatedTables = [...tables];
        updatedTables[index].columns += 1;
        updatedTables[index].cells.forEach((row) => {
            row.push({ backgroundColor: tableProperties.cellBackgroundColor });
        });
        setTables(updatedTables);
    };

    const handleDeleteRow = (index) => {
        const updatedTables = [...tables];
        if (updatedTables[index].rows > 1) {
            updatedTables[index].rows -= 1;
            updatedTables[index].cells.pop();
        }
        setTables(updatedTables);
    };

    const handleDeleteColumn = (index) => {
        const updatedTables = [...tables];
        if (updatedTables[index].columns > 1) {
            updatedTables[index].columns -= 1;
            updatedTables[index].cells.forEach((row) => row.pop());
        }
        setTables(updatedTables);
    };

    const renderShape = (shape, width, height) => {
        const commonStyle = {
            width: `${width}px`,
            height: `${height}px`,
            backgroundColor: 'gray',
        };

        switch (shape) {
            case 'circle':
                return <div style={{ ...commonStyle, borderRadius: '50%' }}></div>;
            case 'rectangle':
                return <div style={commonStyle}></div>;
            case 'square':
                return <div style={commonStyle}></div>;
            case 'star':
                return <div style={{ ...commonStyle, clipPath: 'polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%)' }}></div>;
            case 'triangle':
                return <div style={{ width: 0, height: 0, borderLeft: `${width / 2}px solid transparent`, borderRight: `${width / 2}px solid transparent`, borderBottom: `${height}px solid gray` }}></div>;
            case 'rhombus':
            case 'diamond':
                return <div style={{ ...commonStyle, transform: 'rotate(45deg)' }}></div>;
            case 'line':
                return <div style={{ width: '100%', height: '3px', backgroundColor: 'gray' }}></div>;
            case 'heart':
                return (
                    <div style={{ position: 'relative', width: `${width}px`, height: `${height}px` }}>
                        <div style={{ ...commonStyle, width: `${width / 2}px`, height: `${height / 2}px`, borderRadius: '50% 50% 0 0', transform: 'rotate(-45deg)', position: 'absolute', top: '50%', left: '0' }}></div>
                        <div style={{ ...commonStyle, width: `${width / 2}px`, height: `${height / 2}px`, borderRadius: '50% 50% 0 0', transform: 'rotate(45deg)', position: 'absolute', top: '50%', left: '50%' }}></div>
                    </div>
                );
            case 'hash':
                return <div style={commonStyle}></div>;
            default:
                return null;
        }
    };

    const generatePersonData = () => {
        const hobbiesList = ["Movies", "Study", "Social Surfing", "Gaming", "Reading", "Hiking", "Photography", "Cooking", "Travelling", "Music", "Art", "Fitness", "Blogging", "Writing", "Coding", "Gardening"];
        const generateRandomPerson = (index) => {
            const randomHobbies = hobbiesList.sort(() => 0.5 - Math.random()).slice(0, Math.floor(Math.random() * 4) + 1);
            const genders = ["Male", "Female", "Other"];
            return {
                name: `Person ${index}`,
                gender: genders[Math.floor(Math.random() * genders.length)],
                hobbies: randomHobbies
            };
        };
        const data = [];
        for (let i = 1; i <= 100; i++) {
            data.push(generateRandomPerson(i));
        }
        return data;
    };

    const renderReportDataAcrossPages = () => {
        const itemsPerPage = 7;
        const pages = [];

        for (let i = 0; i < personData.length; i += itemsPerPage) {
            const pageData = personData.slice(i, i + itemsPerPage);

            pages.push(
                <div key={i} className="report-page">
                    {pageData.map((person, index) => (
                        <div key={index}>
                            <h3 style={{ fontSize: 17 }}>Person {index + 1 + i}</h3>
                            <p className='m-0'>Name: {person.name}</p>
                            <p className='m-0'>Gender: {person.gender}</p>
                            <p >Hobbies: {person.hobbies.join(', ')}</p>
                        </div>
                    ))}
                </div>
            );
        }
        return pages;
    };

    useEffect(() => {
        const data = generatePersonData();
        setPersonData(data);
    }, []);

    return (
        <>
            <Toolbar className="mb-1"
                onPrint={handlePrint}
                activeTab={activeTab}
                tocData={tocData}
                setTocData={setTocData}
                setActiveTab={setActiveTab}
                selectedCell={selectedCell}
                onToggleHeader={toggleHeader}
                onToggleFooter={toggleFooter}
                setOrientation={setOrientation}
                onSizeChange={handleSizeChange}
                onImageInsert={handleImageInsert}
                tableProperties={tableProperties}
                onEmojiInsert={handleEmojiInsert}
                onShapeInsert={handleShapeInsert}
                onInsertTable={handleInsertTable}
                onColumnChange={handleColumnChange}
                handleCellChange={handleCellChange}
                onConvertToPDF={handleConvertToPDF}
                handleImageUpload={handleImageUpload}
                handleMarginChange={handleMarginChange}
                updateTableProperties={updateTableProperties}
                addRow={() => handleAddRow(selectedTableIndex)}
                handleReportButtonClick={handleReportButtonClick}
                addColumn={() => handleAddColumn(selectedTableIndex)}
                deleteRow={() => handleDeleteRow(selectedTableIndex)}
                deleteColumn={() => handleDeleteColumn(selectedTableIndex)}
            />
            <EditorWritingPage
                margin={margin}
                tables={tables}
                size={pageSize}
                columns={columns}
                tocData={tocData}
                imageSrc={imageSrc}
                shapeSize={shapeSize}
                setMargin={setMargin}
                setTables={setTables}
                setTocData={setTocData}
                showReport={showReport}
                showHeader={showHeader}
                showFooter={showFooter}
                renderShape={renderShape}
                orientation={orientation}
                setShapeSize={setShapeSize}
                setActiveTab={setActiveTab}
                tableToInsert={tableToInsert}
                shapeToInsert={shapeToInsert}
                emojiToInsert={emojiToInsert}
                imageToInsert={imageToInsert}
                tableProperties={tableProperties}
                setSelectedCell={setSelectedCell}
                setSelectedTableIndex={setSelectedTableIndex}
                getCellAlignmentStyles={getCellAlignmentStyles}
                handleLinePositionChange={handleLinePositionChange}
                renderReportDataAcrossPages={renderReportDataAcrossPages}
            />
        </>
    )
}
export default WordEditorData