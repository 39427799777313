import React, { useEffect, useRef, useState } from 'react';
import { Rnd } from 'react-rnd';
import { Table } from 'react-bootstrap';
import DynamicRuler from './DynamicRuler';

const EditorWritingPage = ({ orientation, setTocData, tocData, columns, setSelectedTableIndex, showHeader, showFooter, handleLinePositionChange,
    imageToInsert, emojiToInsert, imageSrc, setShapeSize, size, tableProperties, renderReportDataAcrossPages, margin,
    shapeToInsert, renderShape, tables, showReport, setSelectedCell, getCellAlignmentStyles, setActiveTab, shapeSize }) => {

    const pageRefs = useRef([]);
    const MAX_PAGE_HEIGHT = 1120;
    const editorRef = useRef(null);
    const [pages, setPages] = useState([0]);

    const rebuildTOC = () => {
        const newToc = [];
        const counters = [0, 0, 0, 0, 0, 0];

        editorRef.current.querySelectorAll('h1, h2, h3, h4, h5, h6').forEach((heading) => {
            const level = parseInt(heading.tagName.replace('H', ''), 10) - 1;

            for (let i = level + 1; i < counters.length; i++) {
                counters[i] = 0;
            }
            counters[level]++;
            const numbering = counters.slice(0, level + 1).join('.');
            const pageIndex = [...pageRefs.current].findIndex((page) => {
                const rect = heading.getBoundingClientRect();
                const pageRect = page.getBoundingClientRect();
                return rect.top >= pageRect.top && rect.bottom <= pageRect.bottom;
            });
            newToc.push({
                text: `${numbering} ${heading.textContent}`,
                level: level + 1,
                id: heading.id,
                page: pageIndex + 1,
            });
        });
        setTocData(newToc);
    };

    useEffect(() => {
        const handleEditorInput = () => rebuildTOC();
        editorRef.current.addEventListener('input', handleEditorInput);
        return () => editorRef.current.removeEventListener('input', handleEditorInput);
    }, []);

    const editorStyle = { width: '100%', minHeight: `${MAX_PAGE_HEIGHT}px` };
    const marginStyle = { paddingTop: margin.top, paddingBottom: margin.bottom, paddingLeft: margin.left, paddingRight: margin.right };
    const headermarginStyle = { paddingLeft: margin.left, paddingRight: margin.right }

    const pageStyle = {
        width: orientation === 'portrait' ? size.width : size.height,
        height: orientation === 'portrait' ? size.height : size.width,
        marginTop: margin.top,
        marginBottom: margin.bottom,
        paddingLeft: margin.left,
        paddingRight: margin.right,
        position: 'relative',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
    };

    const columnData = { columnCount: typeof columns === 'number' ? columns : 1, columnGap: '1em' }
    const contentStyle = { minHeight: "100%", ...marginStyle, ...columnData, flexGrow: 1 }

    const insertImageAtCaret = (imageSrc) => {
        const img = document.createElement('img');
        img.src = imageSrc;
        img.style.maxWidth = '100%';
        img.style.maxHeight = '100%';

        const selection = window.getSelection();
        if (selection.rangeCount > 0) {
            const range = selection.getRangeAt(0);
            range.insertNode(img);
            range.collapse(false);
        }
    };

    const handleContentInput = (pageIndex) => {
        const currentPage = pageRefs.current[pageIndex];

        if (currentPage && currentPage.scrollHeight > MAX_PAGE_HEIGHT) {
            if (pageIndex === pages.length - 1) {
                currentPage.contentEditable = true;
                setPages([...pages, pages.length]);
                setTimeout(() => {
                    const nextPage = pageRefs.current[pageIndex + 1];
                    if (nextPage) {
                        nextPage.focus();
                    }
                }, 0);
            }
        }
    }

    useEffect(() => {
        if (pageRefs.current[0]) {
            pageRefs.current[0].focus();
        }
    }, []);

    useEffect(() => {
        if (imageToInsert) {
            insertImageAtCaret(imageToInsert);
        }
    }, [imageToInsert]);

    const insertEmojiAtCaret = (emoji) => {
        const selection = window.getSelection();
        if (selection.rangeCount > 0) {
            const range = selection.getRangeAt(0);
            const emojiNode = document.createTextNode(emoji);
            range.insertNode(emojiNode);
            range.collapse(false);
        }
    };

    useEffect(() => {
        if (emojiToInsert) {
            insertEmojiAtCaret(emojiToInsert);
        }
    }, [emojiToInsert]);

    return (
        <div className="text-editor-container d-flex flex-column justify-content-center align-item-center p-0 m-0 overflow-auto"
            ref={editorRef}
        >
            <DynamicRuler orientation={orientation} size={size} onLinePositionChange={handleLinePositionChange} />
            {pages?.map((pageIndex) => (
                <div
                    key={pageIndex}
                    className="text-editor mb-2 mt-4 p-2"
                    ref={(el) => (pageRefs.current[pageIndex] = el)}
                    onInput={() => handleContentInput(pageIndex)} contentEditable={true}
                    style={{ border: '1px solid #ccc', padding: '10px', outline: 'none', ...editorStyle, ...pageStyle }}
                    suppressContentEditableWarning={true} >

                    {showHeader && (
                        <div
                            style={{ ...headermarginStyle, width: '100%', textAlign: 'center', borderBottom: '1px dashed #000' }}
                            contentEditable={true}
                        >
                            Header Content
                        </div>
                    )}

                    <div style={contentStyle} >
                        {pageIndex === 0 && tocData.length > 0 && (
                            <div className="table-of-contents">
                                <div className='text-center' style={{ fontWeight: 500, fontSize: 16 }}>Table of Contents</div>
                                <ul>
                                    {tocData?.map((item, index) => (
                                        <li
                                            key={index}
                                            style={{
                                                marginLeft: `${(item.level - 1) * 20}px`,
                                                listStyleType: item.level === 1 ? 'disc' : 'circle',
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}>
                                            <a href={`#${item.id}`}
                                                style={{
                                                    textDecoration: 'none', flex: 1, display: 'flex', alignItems: 'center',
                                                }}>
                                                <span style={{ flex: 'none' }}>
                                                    {item.text}
                                                </span>
                                                <span style={{ flex: 1, borderBottom: '2px dotted', margin: '0 5px' }}></span>
                                            </a>
                                            <span style={{ marginLeft: '5px', whiteSpace: 'nowrap' }}>
                                                {item.page}
                                            </span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}

                        {imageSrc && (
                            <Rnd
                                default={{ x: 50, y: 50, width: 200, height: 200 }}
                                lockAspectRatio={false}
                                bounds="parent"
                            >
                                <img
                                    src={imageSrc}
                                    alt="Selected"
                                    style={{ width: "100%", height: "100%", objectFit: "cover" }}
                                />
                            </Rnd>
                        )}

                        {shapeToInsert && (
                            <Rnd
                                default={{ x: 100, y: 100, width: 100, height: 100 }}
                                lockAspectRatio={false}
                                bounds="parent"
                                onResize={(e, direction, ref, delta, position) => {
                                    setShapeSize({
                                        width: ref.style.width,
                                        height: ref.style.height,
                                    });
                                }}
                            >
                                <div style={{ fontSize: '50px' }}>
                                    {renderShape(shapeToInsert, shapeSize.width, shapeSize.height)}
                                </div>
                            </Rnd>
                        )}

                        {tables?.map((table, index) => (
                            <div key={index} className="table-container" onClick={() => {
                                setActiveTab('Home');
                                setSelectedTableIndex(index);
                            }}>
                                <Table bordered style={{ tableLayout: 'fixed', width: '100%' }}>
                                    <tbody>
                                        {Array(table.rows).fill(0).map((_, rowIndex) => (
                                            <tr key={rowIndex} style={{
                                                minHeight: `${tableProperties.minRowHeight}px`,
                                                position: rowIndex < tableProperties.pinHeaderRows ? 'sticky' : 'static',
                                                top: rowIndex < tableProperties.pinHeaderRows ? 0 : 'auto',
                                                backgroundColor: rowIndex < tableProperties.pinHeaderRows ? '#f8f9fa' : 'inherit',
                                            }}>
                                                {Array(table.columns).fill(0).map((_, colIndex) => (
                                                    <td
                                                        key={colIndex}
                                                        style={{
                                                            padding: `${tableProperties.cellPadding}px`,
                                                            height: `${tableProperties.minRowHeight}px`,
                                                            width: `${tableProperties.columnWidth}px`,
                                                            borderWidth: `${tableProperties.borderWidth}px`,
                                                            borderColor: `${tableProperties.borderColor}`,
                                                            ...getCellAlignmentStyles(tableProperties.cellAlignment),
                                                            backgroundColor: table.cells[rowIndex][colIndex]?.backgroundColor || '#ffffff',
                                                        }}
                                                        contentEditable
                                                        onClick={() => setSelectedCell({ rowIndex, colIndex })}
                                                    ></td>
                                                ))}
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        ))}

                        {showReport && (
                            <div className="report-container">
                                {renderReportDataAcrossPages()}
                            </div>
                        )}

                    </div>

                    {showFooter && (
                        <div
                            style={{ ...headermarginStyle, position: "absolute", width: '100%', marginTop: '10px', textAlign: 'center', bottom: 5, borderTop: '1px dashed #000' }}
                            contentEditable={true}
                        >
                            Footer Content
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default EditorWritingPage;
