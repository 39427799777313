import React, { useEffect, useRef, useState } from "react";

const Particle = () => {
  const containerRef = useRef(null);
  const canvasRef = useRef(null);
  const [stats, setStats] = useState(null);

  const ROWS = 100;
  const COLS = 300;
  const NUM_PARTICLES = ROWS * COLS;
  const THICKNESS = Math.pow(80, 2);
  const SPACING = 3;
  const MARGIN = 100;
  const COLOR = 220;
  const DRAG = 0.95;
  const EASE = 0.25;

  let mouse = { x: 0, y: 0 };
  let man = false;
  let tog = true;
  let list = [];

  const init = () => {
    const container = containerRef.current;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    const w = (canvas.width = COLS * SPACING + MARGIN * 2);
    const h = (canvas.height = ROWS * SPACING + MARGIN * 2);

    container.style.marginLeft = `${Math.round(w * -0.5)}px`;
    container.style.marginTop = `${Math.round(h * -0.5)}px`;

    for (let i = 0; i < NUM_PARTICLES; i++) {
      const particle = {
        x: MARGIN + SPACING * (i % COLS),
        y: MARGIN + SPACING * Math.floor(i / COLS),
        ox: MARGIN + SPACING * (i % COLS),
        oy: MARGIN + SPACING * Math.floor(i / COLS),
        vx: 0,
        vy: 0,
      };
      list.push(particle);
    }

    container.addEventListener("mousemove", (e) => {
      const bounds = container.getBoundingClientRect();
      mouse.x = e.clientX - bounds.left;
      mouse.y = e.clientY - bounds.top;
      man = true;
    });

    if (typeof stats === "function") {
      const statsInstance = new stats();
      document.body.appendChild(statsInstance.domElement);
      setStats(statsInstance);
    }
  };

  const step = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    if (stats) stats.begin();

    if ((tog = !tog)) {
      if (!man) {
        const t = Date.now() * 0.001;
        const w = canvas.width;
        const h = canvas.height;

        mouse.x = w * 0.5 + Math.cos(t * 2.1) * Math.cos(t * 0.9) * w * 0.45;
        mouse.y = h * 0.5 + Math.sin(t * 3.2) * Math.tan(Math.sin(t * 0.8)) * h * 0.45;
      }

      list.forEach((p) => {
        const dx = mouse.x - p.x;
        const dy = mouse.y - p.y;
        const d = dx * dx + dy * dy;
        const f = -THICKNESS / d;

        if (d < THICKNESS) {
          const t = Math.atan2(dy, dx);
          p.vx += f * Math.cos(t);
          p.vy += f * Math.sin(t);
        }

        p.x += (p.vx *= DRAG) + (p.ox - p.x) * EASE;
        p.y += (p.vy *= DRAG) + (p.oy - p.y) * EASE;
      });
    } else {
      const w = canvas.width;
      const h = canvas.height;
      const imageData = ctx.createImageData(w, h);
      const data = imageData.data;

      list.forEach((p) => {
        const n = (~~p.x + ~~p.y * w) * 4;
        data[n] = data[n + 1] = data[n + 2] = COLOR;
        data[n + 3] = 255;
      });

      ctx.putImageData(imageData, 0, 0);
    }

    if (stats) stats.end();

    requestAnimationFrame(step);
  };

  useEffect(() => {
    init();
    step();
  }, []);

  return (
    <div id="container" ref={containerRef}>
      <canvas ref={canvasRef}></canvas>
    </div>
  );
};

export default Particle;
